<template>
	<v-container>
		<v-btn color="primary" class="mr-4" v-for="(action, index) in actions" :key="index" @click.stop="addVacancy(index)">{{action.text}}</v-btn>

		<v-divider class="my-4"></v-divider>

		<v-card elevation="2" v-if="seatsList.length">
			<v-card-title>
				<div>{{actions[freeSeatsTypeSelected].text}}</div>
				<v-spacer></v-spacer>
				<span><v-btn icon @click="closeCard"><v-icon>mdi-close</v-icon></v-btn></span>
			</v-card-title>
			<v-card-text>
				<v-form class="form" ref="addSeatsForm" :readonly="isLoading">
					<v-simple-table>
						<thead>
							<tr>
								<th width="50%">Группа</th>
								<th v-if="freeSeatsTypeSelected == 2">Дата пребывания</th>
								<th v-if="freeSeatsTypeSelected == 3">Дата окончания</th>
								<th>Возраст</th>
								<th>Количество мест</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in seatsList" :key="index">
								<td>
									<v-select
										v-model="item.seatsGroup"
										:items="groupList"
										item-text="name"
										item-value="id"
										:rules="[v => v != null || 'Required!']"
										return-object
										@change="item.seatsAge = null"
										label="Выберите группу">
										<template v-slot:selection="{ item }">
											<div>
												{{ item.name }} / {{ item.group_name }} / {{ item.hour_name }} / {{ item.lang_name }} / {{ item.ages }} лет
											</div>
										</template>
										<template v-slot:item="{ item }">
											<div>
												{{ item.name }} / {{ item.group_name }} / {{ item.hour_name }} / {{ item.lang_name }} / {{ item.ages }} лет
											</div>
										</template>
									</v-select>
								</td>
								<td v-if="freeSeatsTypeSelected == 2 || freeSeatsTypeSelected == 3">
									<v-menu
										v-model="item.seatsDatePickerMenu"
										:close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="item.seatsDate"
												label="Дата"
												readonly
												prepend-icon="mdi-calendar"
												:rules="[v => !!v || 'Required!']"
												required
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
										v-model="item.seatsDate"
										@input="item.seatsDatePickerMenu = false"
										></v-date-picker>
									</v-menu>
								</td>
								<td>
									<v-select
										v-if="item.seatsGroup"
										v-model="item.seatsAge"
										:items="Array.from({length: (item.seatsGroup.end_age - item.seatsGroup.start_age)}, (el, index)=> (item.seatsGroup.start_age + index))"
										required
										:rules="[v => typeof v !== 'object' || 'Required!']"
										label="Возраст">
										<template v-slot:selection="{ item }">
											<div>
												{{ item }} лет
											</div>
										</template>
										<template v-slot:item="{ item }">
											<div>
												{{ item }} лет
											</div>
										</template>
									</v-select>
								</td>
								<td>
									<v-text-field
										v-model="item.seatsCount"
										label="Количество мест"
										type="number"
										:rules="[v => !!v || 'Required!', v => v > 0 || 'Must be > 0']"
										required
									></v-text-field>
								</td>
								<td align="right">
									<v-btn v-if="seatsList.length != index + 1" color="error" @click="removeRow(index)" fab small depressed>
										<v-icon dark>mdi-close</v-icon>
									</v-btn>
									<v-btn v-if="seatsList.length == index + 1" color="success" @click="addRow" fab small depressed>
										<v-icon dark>mdi-plus</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer><v-btn color="primary"  @click="addSeats" class="mr-3 mb-3" :loading="isLoading">Сохранить</v-btn>
			</v-card-actions>
		</v-card>
    </v-container>
</template>

<script>
	export default {
		mounted() {
			this.getGroups();
		},
		data: () => ({
			isLoading: true,
			groupList: [],
			freeSeatsTypeSelected: null,
			seatsList: [],
			actions: {
				1: {
					text: 'Добавить общеустановленные места'
				},
				2: {
					text: 'Добавить места раннего бронирование'
				},
				3: {
					text: 'Добавить места временного бронирование'
				}
			}
		}),
		methods: {
			async getGroups() {
				await this.$http.get('/group/all?pagination=0').then((response) => {
					this.groupList = response.data.data.kindergartenGroupList.list;
					//console.log(this.groupList);
					this.isLoading = false;
				});
			},
			addVacancy(type) {
				this.freeSeatsTypeSelected = type;
				if(this.seatsList.length) {
					this.$refs.addSeatsForm.reset();
				}
				if(!this.seatsList.length || confirm('warning')) {
					this.seatsList = [];
					this.addRow();
				}
			}, 
			addRow() {
				//console.log(this.seatsList);
				this.seatsList.push({
					seatsGroup: null,
					seatsAge: null,
					seatsCount: null,
					seatsDate: null,
					seatsDatePickerMenu: false,
				});
			},
			removeRow(index) {
				this.seatsList.splice(index, 1); 
			},
			closeCard() {
				if(confirm('warning')) {
					this.freeSeatsTypeSelected = null;
					this.seatsList = [];
				}
			}, 
			async addSeats() {
				if(this.$refs.addSeatsForm.validate()) {
					this.isLoading = true;
					for(let index = 0; index < this.seatsList.length;) {
						await this.$http.post('/free_seats/add', {
							"kgId": this.seatsList[index].seatsGroup.id,
							"fptId": this.freeSeatsTypeSelected,
							"age": this.seatsList[index].seatsAge,
							"date": this.seatsList[index].seatsDate,
							"count": this.seatsList[index].seatsCount,
						}).then((response) => {
							if(response.data.status == 'success') {
								this.removeRow(index);
							} else {
								this.$store.dispatch('alertAdd', {text: response.data.message, type: 'error'});
								index++;
							}
						}).catch(() => {
							index++;
						});
						
					}
					this.isLoading = false;
					this.$store.dispatch('alertAdd', {text: "Все места успешно добавлены! Чтобы посмотреть места, переходите в раздел \"Гурппы\"", type: 'success'});
				}
			},
			check() {
				alert("Please save the list")
			}
		}
	};
</script>
<style scoped>

</style>