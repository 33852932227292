var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._l((_vm.actions),function(action,index){return _c('v-btn',{key:index,staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addVacancy(index)}}},[_vm._v(_vm._s(action.text))])}),_c('v-divider',{staticClass:"my-4"}),(_vm.seatsList.length)?_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('div',[_vm._v(_vm._s(_vm.actions[_vm.freeSeatsTypeSelected].text))]),_c('v-spacer'),_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeCard}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"addSeatsForm",staticClass:"form",attrs:{"readonly":_vm.isLoading}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"50%"}},[_vm._v("Группа")]),(_vm.freeSeatsTypeSelected == 2)?_c('th',[_vm._v("Дата пребывания")]):_vm._e(),(_vm.freeSeatsTypeSelected == 3)?_c('th',[_vm._v("Дата окончания")]):_vm._e(),_c('th',[_vm._v("Возраст")]),_c('th',[_vm._v("Количество мест")]),_c('th')])]),_c('tbody',_vm._l((_vm.seatsList),function(item,index){return _c('tr',{key:index},[_c('td',[_c('v-select',{attrs:{"items":_vm.groupList,"item-text":"name","item-value":"id","rules":[function (v) { return v != null || 'Required!'; }],"return-object":"","label":"Выберите группу"},on:{"change":function($event){item.seatsAge = null}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" / "+_vm._s(item.group_name)+" / "+_vm._s(item.hour_name)+" / "+_vm._s(item.lang_name)+" / "+_vm._s(item.ages)+" лет ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" / "+_vm._s(item.group_name)+" / "+_vm._s(item.hour_name)+" / "+_vm._s(item.lang_name)+" / "+_vm._s(item.ages)+" лет ")])]}}],null,true),model:{value:(item.seatsGroup),callback:function ($$v) {_vm.$set(item, "seatsGroup", $$v)},expression:"item.seatsGroup"}})],1),(_vm.freeSeatsTypeSelected == 2 || _vm.freeSeatsTypeSelected == 3)?_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Дата","readonly":"","prepend-icon":"mdi-calendar","rules":[function (v) { return !!v || 'Required!'; }],"required":""},model:{value:(item.seatsDate),callback:function ($$v) {_vm.$set(item, "seatsDate", $$v)},expression:"item.seatsDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.seatsDatePickerMenu),callback:function ($$v) {_vm.$set(item, "seatsDatePickerMenu", $$v)},expression:"item.seatsDatePickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){item.seatsDatePickerMenu = false}},model:{value:(item.seatsDate),callback:function ($$v) {_vm.$set(item, "seatsDate", $$v)},expression:"item.seatsDate"}})],1)],1):_vm._e(),_c('td',[(item.seatsGroup)?_c('v-select',{attrs:{"items":Array.from({length: (item.seatsGroup.end_age - item.seatsGroup.start_age)}, function (el, index){ return (item.seatsGroup.start_age + index); }),"required":"","rules":[function (v) { return typeof v !== 'object' || 'Required!'; }],"label":"Возраст"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item)+" лет ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item)+" лет ")])]}}],null,true),model:{value:(item.seatsAge),callback:function ($$v) {_vm.$set(item, "seatsAge", $$v)},expression:"item.seatsAge"}}):_vm._e()],1),_c('td',[_c('v-text-field',{attrs:{"label":"Количество мест","type":"number","rules":[function (v) { return !!v || 'Required!'; }, function (v) { return v > 0 || 'Must be > 0'; }],"required":""},model:{value:(item.seatsCount),callback:function ($$v) {_vm.$set(item, "seatsCount", $$v)},expression:"item.seatsCount"}})],1),_c('td',{attrs:{"align":"right"}},[(_vm.seatsList.length != index + 1)?_c('v-btn',{attrs:{"color":"error","fab":"","small":"","depressed":""},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1):_vm._e(),(_vm.seatsList.length == index + 1)?_c('v-btn',{attrs:{"color":"success","fab":"","small":"","depressed":""},on:{"click":_vm.addRow}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1)])}),0)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3 mb-3",attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.addSeats}},[_vm._v("Сохранить")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }